import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';
import SiteMap from '../components/footer/SiteMap';

const FormNewsletter = loadable(() => import('../components/footer/FormNewsletter'));

const Footer = memo(({ before_container, before_row, after_container, after_row }) => {
  const isBrowser = typeof window !== 'undefined';

  const isMobile = isBrowser && window.innerWidth < 992;

  const data = useStaticQuery(query);

  const footers = data.allPrismicFooterNavigation.nodes.map((node) => {
    const { footer_column_title, position, body } = node.data;
    return { footer_column_title, position, body };
  });

  footers.sort((a, b) => a.position - b.position);

  return (
    <footer id="footer" className="footer -bg-color-primary">
      {before_container}
      <div className="footer__container container-xl">
        {isMobile && (
          <div className="row">
            <div className="footer__container__subscribe col --mobile text-lg-left">
              <FormNewsletter />
            </div>
          </div>
        )}

        {before_row}
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              {footers.map(({ body, footer_column_title }, index) => {
                const child = body.length > 0 && body[0].items;
                return (
                  <SiteMap
                    key={index}
                    index={index}
                    footer_column_title={footer_column_title}
                    child={child}
                  />
                );
              })}
            </div>
          </div>

          {!isMobile && (
            <div className="footer__container__subscribe col-lg-4 text-center text-lg-left">
              <FormNewsletter />
            </div>
          )}

          <div className="col-12 copy-right">
            <address>© ActiveSkin. All rights reserved</address>
          </div>
        </div>
        {after_row}
      </div>
      {after_container}
    </footer>
  );
});
export default Footer;

export const query = graphql`
  query {
    allPrismicFooterNavigation {
      nodes {
        data {
          footer_column_title
          position
          body {
            ... on PrismicFooterNavigationDataBodyLinks {
              items {
                link_name
                url {
                  url
                  uid
                  type
                  link_type
                  document {
                    ... on PrismicBrand {
                      url
                    }
                  }
                }
                icon {
                  gatsbyImageData(width: 20, breakpoints: 20, height: 20)
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`;
