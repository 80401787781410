import React, { memo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { serializeHyperlink, checkExpried } from '../../utils/helpers';
import { RichText } from 'prismic-reactjs';

const HeaderAlertBar = memo(() => {
  const data = useStaticQuery(query)?.prismicHeaderAlertB?.data;

  const texts = data?.alert_text_group?.filter(
    (alert) => !checkExpried(alert?.start_date, alert?.end_date)
  );

  return (
    <div className="widget -notice">
      <style>
        {`.widget__content div:nth-child(n) {
            animation-duration: ${texts.length * 5}s;
        }`}
      </style>
      <div className="widget__content" style={{ backgroundColor: data?.background_colour }}>
        {texts.map((alert, i) => (
          // <a href="https://www.activeskin.com.au/blog/goodbuy-july-sale/" target="blank">
          //     <span>Save 15% on selected brands for 2 days only. Use code: YAY15 </span>
          // </a>
          <div key={i}>
            <RichText render={alert.alert_text.richText} serializeHyperlink={serializeHyperlink} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default HeaderAlertBar;

export const query = graphql`
  query HeaderAlertBar {
    prismicHeaderAlertB {
      data {
        background_colour
        alert_text_group {
          start_date
          end_date
          alert_text {
            richText
          }
        }
      }
    }
  }
`;
