import React, { useEffect, useState } from 'react';
import { loaded } from '../../utils/helpers';
import { isLogged, getToken, getUrlMultypass } from '../../services/customer';
import loadable from '@loadable/component';

const ResetPassword = loadable(() => import('./ResetPassword'));
const Register = loadable(() => import('./Register'));
const Login = loadable(() => import('./Login'));
const Dialog = loadable(() => import('@mui/material/Dialog'));

function Customer() {
  const [state, setState] = useState('');
  const hash = loaded ? location.hash : '';

  useEffect(() => {
    if (!isLogged()) {
      if (hash?.indexOf('#login') !== -1) setState('login');
      else if (hash?.indexOf('#register') !== -1) setState('register');
      else if (hash?.indexOf('#reset-password') !== -1) setState('reset-password');
      else setState('');
    } else if (hash?.indexOf('#login') !== -1 || hash?.indexOf('return_url') !== -1) {
      const { email } = getToken();
      getUrlMultypass(email, '/account').then((url) => {
        location.href = url;
      });
    }
  }, [hash]);

  const handleClose = () => {
    location.hash = '';
    setState('');
  };

  return (
    <Dialog open={state !== ''} onClose={handleClose} maxWidth="sm">
      {state === 'login' && <Login />}
      {state === 'register' && <Register />}
      {state === 'reset-password' && <ResetPassword />}
    </Dialog>
  );
}

export default Customer;
